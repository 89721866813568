import React, { useState } from 'react'
import PropTypes from 'prop-types'
import loadable from '@loadable/component'
import { Formik, Form } from 'formik'
import * as Yup from 'yup'
import submitStandardForm from '/src/functions/forms/submitStandardForm'

const Alert = loadable(() => import('/src/components/Basic/Alert'))
const CheckBox = loadable(() => import('/src/components/Form/CheckBox'))
const CtaContainer = loadable(() => import('/src/components/Basic/CtaContainer'))
const FormRow = loadable(() => import('/src/components/Form/FormRow'))
const RequiredText = loadable(() => import('/src/components/Form/Required/RequiredText'))
const Select = loadable(() => import('/src/components/Form/Select'))
const SubmitButton = loadable(() => import('/src/components/Form/SubmitButton'))
const TextAreaField = loadable(() => import('/src/components/Form/TextAreaField'))
const TextField = loadable(() => import('/src/components/Form/TextField'))

const validationSchema = Yup.object({
    name: Yup.string().required('Full name is required'),
    email: Yup.string().email('Email address is not valid').required('Email is required'),
    issueType: Yup.string().required('This field is required'),
    issue: Yup.string().required('This field is required'),
    update: Yup.boolean()
})

const TechnicalEnquiryForm = ({ disclaimer }) => {
    const [isSubmitted, setSubmitted] = useState(false)

    const issueTypes = ['Accessibility', 'Bug', 'Other']

    return (
        <Formik
            initialValues={{
                name: '',
                email: '',
                issueType: '',
                issue: '',
                update: ''
            }}
            onSubmit={async (values, actions) => {
                const firebaseData = {
                    createdAt: new Date(),
                    name: values.name,
                    email: values.email,
                    issueType: values.issueType,
                    issue: values.issue,
                    update: values.update
                }

                const submitOptions = {
                    actions: actions,
                    firebaseCollection: 'technical-enquiry-form',
                    firebaseData: firebaseData,
                    confirmationEmail: process.env.GATSBY_MARKETING_EMAIL_ADDRESS,
                    emailTemplate: 'technical-enquiry-form-template'
                }

                setSubmitted(true)

                await submitStandardForm(submitOptions)
            }}
            validationSchema={validationSchema}
        >
            {({ isSubmitting, isValid, dirty }) => (
                <Form>
                    <RequiredText />

                    <FormRow>
                        <TextField
                            label={'Full name'}
                            name={'name'}
                            placeholder={'e.g. Emma Jones'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextField
                            label={'Email address'}
                            name={'email'}
                            type={'email'}
                            placeholder={'e.g. name@email.com'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <Select
                            label={'Issue type'}
                            name={'issueType'}
                            firstItem={'Please select'}
                            data={issueTypes}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <TextAreaField
                            label={'Please explain the issue'}
                            name={'issue'}
                            placeholder={
                                'Tell us about the issue you are experiencing with as much detail as possible...'
                            }
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            required
                        />
                    </FormRow>

                    <FormRow>
                        <CheckBox
                            label={'I would like to be updated about this'}
                            name={'update'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                        />
                    </FormRow>

                    {disclaimer && <Alert content={disclaimer} />}

                    <CtaContainer align={'right'}>
                        <SubmitButton
                            initialText={'Send enquiry'}
                            sentText={'Enquiry sent!'}
                            isSubmitting={isSubmitting}
                            isSubmitted={isSubmitted}
                            disabled={!(isValid && dirty)}
                        />
                    </CtaContainer>
                </Form>
            )}
        </Formik>
    )
}

TechnicalEnquiryForm.propTypes = {
    /**
     * Disclaimer
     */
    disclaimer: PropTypes.string
}

TechnicalEnquiryForm.defaultProps = {
    disclaimer: '<p>This is the disclaimer message</p>'
}

export default TechnicalEnquiryForm
